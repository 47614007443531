import React from "react";
import logo from "../Images/logo_tagline.png";
import "../App.css";


const styles = {
    outerDiv: {
        height:"100vh", 
        width:"100vw", 
        backgroundColor:"white", 
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
    },
    logoImg: {
        width:"70%"
    }
}

function IntroPage(){
    return (
        <>
            <div className="introLogo" style={ styles.outerDiv}>
                <img src={logo} alt={"Logo"} style={ styles.logoImg}/>
            </div>
        </>
    );
}

export default IntroPage;