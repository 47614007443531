import React, { useState } from "react";
import logo from "../Images/logo_tagline.png"
function LoginPage() {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    city: '',
    state: '',
    address1: '',
    address2: '',
    address3: '',
    phone: '',
    pincode: ''
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log(formData)
  };
  const handleSelectChange = (e) => {
    setFormData({ ...formData, state: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // const response = await axios.post('https://example.com/api/submit', formData);
      // console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const styles = {
    logoImg: {
      height: "25px",
      margin: "10px"
    },
    regButton: {
      margin: "5px",
      padding: "5px 10px 5px 10px",
      color: "white",
      border: "0px"
    }
  };
  return (
    <div>
      <div className="navbar">
        <img src={logo} alt={"Logo"} style={styles.logoImg} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginInline: 20 }}>
        <form class="w-full max-w-lg" onSubmit={handleSubmit}>
          <h6 style={{ textAlign: 'center', fontSize: 50, marginBlock: 30, marginTop: "70px", marginBottom: "5px", fontWeight: "bolder" }}><span style={{ color: "rgb(69,103,27)" }}>Registration</span> <span style={{ color: "rgb(54,23,4)" }}>Form</span></h6>
          <div class="flex flex-wrap -mx-3 mb-6 ">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                First Name
              </label>
              <input class="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3 leading-tight border-cyan-600 focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="First name" name="firstname" value={formData.firstname} onChange={handleChange} />
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs border-cyan-600 font-bold mb-2" for="grid-last-name">
                Last Name
              </label>
              <input name="lastname" value={formData.lastname} onChange={handleChange} class="appearance-none block w-full border border-cyan-600  text-gray-700 border  rounded py-3 px-4 border-cyan-600 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Last Name" />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                On which Address shall we send you email?
              </label>
              <input name="email" value={formData.email} onChange={handleChange} class="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3 leading-tight border-cyan-600 focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="email" placeholder="Enter Your Email Address" />
              {/* <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                How can we text you without your phone number?
              </label>
              <input name="phone" value={formData.phone} onChange={handleChange} class="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight border-cyan-600 focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="tel" placeholder="Enter Your Phone Number" />
              {/* <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                Just in case we need to visit you
              </label>
              <input name="address1" value={formData.address1} onChange={handleChange} class="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight border-cyan-600 focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Address Line 1" />
              <input name="address2" value={formData.address2} onChange={handleChange} class="appearance-none block w-full  text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight border-cyan-600 focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Address Line 2" />
              <input name="address3" value={formData.address3} onChange={handleChange} class="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3 leading-tight border-cyan-600 focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Address Line 3" />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-2">
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
                City
              </label>
              <input name="city" value={formData.city} onChange={handleChange} class="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 leading-tight border-cyan-600 focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" placeholder="City" />
            </div>
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                State
              </label>
              <div class="relative">
                <select name="state" value={formData.state} onChange={handleSelectChange} class="block appearance-none w-full  border border-cyan-600 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white " id="grid-state">
                  <option>Andhra Pradesh</option>
                  <option>Arunachal Pradesh</option>
                  <option>Assam</option>
                  <option>Bihar</option>
                  <option>Chhattisgarh</option>
                  <option>Goa</option>
                  <option>Gujarat</option>
                  <option>Haryana</option>
                  <option>Himachal Pradesh</option>
                  <option>Jharkhand</option>
                  <option>Karnataka</option>
                  <option>Kerala</option>
                  <option>Madhya Pradesh</option>
                  <option>Maharashtra</option>
                  <option>Manipur</option>
                  <option>Meghalaya</option>
                  <option>Mizoram</option>
                  <option>Nagaland</option>
                  <option>Odisha</option>
                  <option>Punjab</option>
                  <option>Rajasthan</option>
                  <option>Sikkim</option>
                  <option>Tamil Nadu</option>
                  <option>Telangana</option>
                  <option>Tripura</option>
                  <option>Uttar Pradesh</option>
                  <option>Uttarakhand</option>
                  <option>West Bengal</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                Postal Code
              </label>
              <input name="pincode" value={formData.pincode} onChange={handleChange} class="appearance-none block w-full  text-gray-700 border border-cyan-600 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-zip" type="text" placeholder="90210" />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button type="submit" class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-32 py-2.5 text-center me-2 mb-10 mt-8">Submit</button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default LoginPage;