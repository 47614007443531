import React from "react";
import BenCard from "./BenCard";

import logo1 from "../Images/fourLogo1.png";
import logo2 from "../Images/fourLogo2.png";
import logo3 from "../Images/fourLogo3.png";
import logo4 from "../Images/fourLogo4.png";

const BenData = {
    card1: {
        title: "Personalized Farming",
        body: "Lease a plot of land and choose the crops you want. Enjoy fresh, organic produce grown just for you"
    },
    card2: {
        title: "Elite Community",
        body: "Connect with prominent individuals, share ideas, and grow together in a community of achievers"
    },
    card3: {
        title: "Unique Experiences",
        body: "Experience farm life, eco-tourism, and nature's beauty in a serene setting."
    },
    card4: {
        title: "Support Local Farmers and enviroment",
        body: "Contribute to sustainable farming and support local farmers and reduce carbon footprint."
    }
};

function BenCardComponent(){
    return (
        <div className="BenCardContainer">
            <BenCard logo={logo1} title={BenData.card1.title} body={BenData.card1.body}/>
            <BenCard logo={logo2} title={BenData.card2.title} body={BenData.card2.body}/>
            <BenCard logo={logo3} title={BenData.card3.title} body={BenData.card3.body}/>
            <BenCard logo={logo4} title={BenData.card4.title} body={BenData.card4.body}/>
        </div>
    )
}

export default BenCardComponent;