import React from "react";

function AboutUs2(){
    return (
        <div className="AboutUsContainer">
            <div>
                <div>
                    <h6 className="AboutUsHeading" style={{fontSize: 40, fontWeight: "bolder", textAlign: "center"}} ><span style={{ color: "rgb(69,103,27)" }}>ABOUT</span> <span style={{ color: "rgb(54,23,4)" }}>US</span></h6>
                </div>
            </div>
            <div>
                <div>
                    <p style={{ textAlign: 'center', fontWeight: 3}}>Welcome to GRASRUT - Pioneers in Community-Supported Agriculture</p>
                    <p style={{ textAlign: 'justify'}}>At Grass, we believe in transforming the way people connect with their food and environment. Our mission is to create a unique community through our flagship initiative, GRASRUT, where luxury meets sustainability in agriculture.</p>
                    <p style={{ textAlign: 'justify'}}>We lease prime farmland on the outskirts of towns, providing an exclusive opportunity for individuals to be directly involved in organic farming. Our innovative approach allows you to lease a portion of our fertile land and choose the crops you wish to grow, ensuring that you receive fresh, pure, and organic produce directly from your own plot.</p>

                </div>
            </div>
        </div>
    );
}

export default AboutUs2;