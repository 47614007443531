import React from "react";

function HowItWorks2(){
    return (
        <>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <h6 style={{ textAlign: 'center', fontSize: 50, fontWeight: "bolder", marginTop: "20px", marginBottom: "20px" }}><span style={{ color: "rgb(69,103,27)" }}>How It</span> <span style={{ color: "rgb(54,23,4)" }}>Works?</span></h6>
                <div>
                    <p style={{ textAlign: 'left', fontSize: 20, marginBottom: "5px"}}>Join the waitlist and secure your spot</p>
                    <p style={{ textAlign: 'left', fontSize: 20, marginBottom: "5px" }}>Receive updates and exclusive invitations</p>
                    <p style={{ textAlign: 'left', fontSize: 20, marginBottom: "5px" }}> Get on board when we launch and start  journey with us</p>
                </div>
            </div>
        </>
    );
}

export default HowItWorks2;