import React from "react";

const styles = {
    outer: {
        height: "250px",
        width: "250px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 0 10px grey"
    },
    inner: {
        height: "220px",
        width: "220px",
        border: "0.1px solid black",
        borderRadius: "50%",
        overflow: "hidden"
    }
}

function Photo(props){
    return (
        <div style={styles.outer}>
            <div style={styles.inner}>
                <img src={props.photo} alt={"some image"} height={"100%"} width={"100%"}/>
            </div>
        </div>
    );
}

export default Photo;