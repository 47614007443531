import React from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";


const styles = {
    waitlist: {
        color: "white",
        padding: "10px",
        borderRadius: "4px"
    }
}

function HeroSection(){
    const navigate = useNavigate();
    return (
        <div className="heroSection">
            <h2 style={{margin: "10px"}}>
                Exclusive Organic Farming & Elite Networking
            </h2>
            <h4 style={{margin: "10px"}}>
                Become a founding member and enjoy premium access to fresh organic produce, unique experiences, and a community of like-minded individuals
            </h4>
            <button style={styles.waitlist} className="blackButton" onClick={()=>{navigate("register");}}>
                <h4>Join the waitlist</h4>
            </button>
        </div>
    )
}

export default HeroSection;