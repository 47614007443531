import React from "react";

function BenCard(props){
    return (
        <div className="benCard">
            <img width={"50%"} src={props.logo} style={{"margin":"10px"}}/>
            <h4 style={{"margin":"10px"}}>{props.title}</h4>
            <p style={{"margin":"10px"}}>{props.body}</p>
        </div>
    );
}

export default BenCard;