import React from "react";
import IntroPage from "../components/IntroPage";
import NavBar from "../components/NavBar";
import HeroSection from "../components/HeroSection";
import BenCardComponent from "../components/BenCardComponent";
import Founders from "../components/Founders";
import Carousel from "../components/Carousel";
import Faqs from "../components/FAQS";
import AboutUs2 from "../components/AboutUs2";
import Footer from "../components/Footer";
import HowItWorks2 from "../components/HowItWorks2";

function LandingPage(){
    return (
        <>
            <NavBar />
            <IntroPage />
            <div style={{height: "100vh"}}></div>
            <div style={{height: "25vh", backgroundImage: "linear-gradient(rgba(255,255,255,0), rgb(255,255,255, 100))"}}></div>
            <div style={{backgroundColor: "white"}}>
                <HeroSection />
                <AboutUs2/>
                <BenCardComponent />
                <HowItWorks2/>
                <Carousel/>
                <Founders />
                <Faqs/>
                <Footer/>
            </div>
        </>
    );
}

export default LandingPage;