import React from "react";
import Photo from "./Photo";

function IdBatch(props){
    return (
        <div style={
            {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "35px"
            }
        }>
            <Photo photo={props.photo}/>
            <h3 style={{"textAlign": "center", "marginBottom": "5px", "marginTop": "15px"}}>{props.name}</h3>
            <h6 style={{"textAlign": "center", "margin": "5px"}}>{props.designation}</h6>
        </div>
    );
}

export default IdBatch;