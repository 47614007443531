import React from "react";
import IdBatch from "./IdBatch";

import photo1 from "../Images/photo1.jpeg";
import photo2 from "../Images/photo2.jpeg";
import photo3 from "../Images/photo3.jpeg";
import photo4 from "../Images/photo4.jpeg";

function Founders(){
    return (
        <div style={{
            "display": "flex",
            "flexDirection": "column",
            "alignItems": "center",
            "justifyContent": "center"
        }}>
            <h1 style={{"textAlign":"center", "fontSize":"50px", "fontWeight": "bold"}}><span style={{"color":"rgb(69,103,27)"}}>Our </span><span style={{"color":"rgb(54,23,4)"}}>Founders</span></h1>
            <div className={"founders"}>
                <IdBatch photo={photo1} name={"Anuradha kumari"} designation={"Founder"}/>
                <IdBatch photo={photo2} name={"Kumari deepika rani"} designation={"Founder"}/>
                <IdBatch photo={photo3} name={"Diya rani"} designation={"Founder"}/>
                <IdBatch photo={photo4} name={"Rina Kumari"} designation={"Founder"}/>
            </div>
        </div>
    );
}

export default Founders;