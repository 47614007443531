import React , { useState }from "react";
import "../App.css";
const FAQ = ({ faq, index, toggleFAQ }) => {
    return (
      <div
        className={"faq " + (faq.open ? "open" : "")}
        key={index}
        onClick={() => toggleFAQ(index)}
      >
        <div className="faq-question">{faq.question}</div>
        <div className="faq-answer">{faq.answer}</div>
      </div>
    );
  };
function Faqs(){
        const [faqs, setFaqs] = useState([
            {
              question: "How many programmers does it take to screw a lightbulb?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra lorem eu dolor rhoncus, at scelerisque ligula gravida. Sed porta id mi sit amet convallis. Etiam iaculis massa sit amet lacus blandit sodales. Nulla ultrices velit a diam placerat congue. Pellentesque iaculis, ipsum quis eleifend dapibus, est dui eleifend ante, quis fermentum mi ligula quis nisl. Ut et ex dui. Integer id venenatis quam.",
              open: false
            },
            {
              question: "Who is the most awesome person?",
              answer: "You! The viewer!",
              open: false
            },
            {
              question:
                "How many questions does it take to makes a succesful FAQ Page?",
              answer: "This many!",
              open: false
            }
          ]);
        
          const toggleFAQ = index => {
            setFaqs(
              faqs.map((faq, i) => {
                if (i === index) {
                  faq.open = !faq.open;
                } else {
                  faq.open = false;
                }
        
                return faq;
              })
            );
          };
        
          return (
            <div className="App">
                <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl  text-center">Frequently Asked Questions</h1>
              <div className="faqs">
                {faqs.map((faq, index) => (
                  <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                ))}
              </div>
            </div>
    )
}
export default Faqs;

