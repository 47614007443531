import React , { useState, useEffect }from "react";
import { SlSocialLinkedin,SlSocialInstagram,S1SocialYoutube,SiGmail } from "react-icons/sl";
import { CiYoutube } from "react-icons/ci";
import logo from "../Images/logo_tagline.png";
import "../App.css";

function Footer(){
    const [isMobile, setIsMobile] = useState(window.innerWidth > 1000);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth > 1000);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      console.log(windowWidth)
    return (
        <>
    {isMobile ? (<div style={{marginTop:10000/windowWidth}}>
    <hr class=" border-gray-200 sm:mx-auto dark:border-gray-700 z-0 " />
<footer class="p-4 bg-white rounded-lg shadow md:px-6 md:py-8">
    <div class="sm:flex sm:items-center sm:justify-between">
        <a href="#" target="_blank" class="flex items-center mb-4 sm:mb-0" style={{display:'flex',justifyContent:'center'}}>
            <img src={logo} class="mr-4 h-8" alt="Flowbite Logo" />
        </a>
        <ul class="flex flex-wrap items-center "style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',}}>
            <li >
                <p class="mr-4 text-sm text-gray-500 hover:underline md:mr-6 dark:text-gray-400 " >1234567890</p>
            </li>
            <li >
                <p class="mr-4 text-sm text-gray-500 hover:underline md:mr-6 dark:text-gray-400" >grasut12@gmail.com</p>
            </li>
           
            <li class="sm:mt-4" style={{marginLeft:'0%'}}>
                <div style={{display:'flex',justifyContent:"space-around" ,columnGap:20}}>
                <div className="buttonBox"><SlSocialLinkedin size={30}/></div>
                <div className="buttonBox"><SlSocialInstagram size={30}/></div>
                <div className="buttonBox"><CiYoutube size={30}/></div>
                </div>
            </li>
        </ul>
    </div>
</footer>
</div>)
:
(<div style={{marginTop:180000/windowWidth}}>
    <hr class=" border-gray-200 sm:mx-auto dark:border-gray-700 z-0 " />
<footer class="p-4 bg-white rounded-lg shadow md:px-6 md:py-8">
    <div class=" sm:items-center">
        <a href="#" target="_blank" class="flex items-center mb-4 " style={{display:'flex',justifyContent:'center'}}>
            <img src={logo} class="mr-4 h-8" alt="Flowbite Logo" />
        </a>
        <ul class="items-center ">
            <li style={{display:'flex',justifyContent:'center'}}>
                <a href="#" class="mr-4 text-sm text-gray-500 hover:underline md:mr-6 dark:text-gray-400 " >1234567890</a>
            </li>
            <li style={{display:'flex',justifyContent:'center',marginBottom:20,marginTop:10}}>
                <a href="#" class="mr-4 text-sm text-gray-500 hover:underline md:mr-6 dark:text-gray-400" >grasut12@gmail.com</a>
            </li>
           
            <li class="sm:mt-4" style={{marginLeft:'0%'}}>
                <div style={{display:'flex',justifyContent:"space-around" ,columnGap:20}}>
                <div className="buttonBox"><SlSocialLinkedin size={30}/></div>
                <div className="buttonBox"><SlSocialInstagram size={30}/></div>
                <div className="buttonBox"><CiYoutube size={30}/></div>
                </div>
            </li>
        </ul>
    </div>
</footer>
</div>)
}

        </>
    )
}
export default Footer;