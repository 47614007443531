import React from "react";
import logo from "../Images/logo_tagline.png";
import { useNavigate } from "react-router-dom";
import "../App.css";


const styles = {
    logoImg: {
        height: "25px",
        margin: "10px"
    },
    regButton: {
        margin: "5px",
        padding: "5px 10px 5px 10px",
        color: "white",
        border: "0px"
    }
};

function NavBar(){
    const navigate = useNavigate();

    return (
        <div className="navbar">
             <div onClick={()=>{navigate("/");}} ><img src={logo} alt={"Logo"} style={ styles.logoImg} /></div>
             <button className="blackButton" style={styles.regButton} onClick={()=>{navigate("register");}}>Register</button>
        </div>
    )
}

export default NavBar;